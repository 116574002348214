<template>
  <div class="uk-container uk-form-width-large">
    <vue-headful
      description="repay fxTokens"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | repay fxTokens`"
    />
    <RepayComponent />
  </div>
</template>

<script>
import { store } from "@/store";
import RepayComponent from "@/components/RepayComponent";

export default {
  name: "Repay",
  components: {
    RepayComponent,
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
    account() {
      return store.state.account;
    },
  },
};
</script>
